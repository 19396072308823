<template>
	<div>
		<a-layout style="min-width: 1200px;overflow: auto;">
			<a-layout-header>
				<div class="top-menu flex wrap">
					<div class="logo">
						<img src="https://img.d.xhmvip.com/b26d5aa2c68cc2726272a26e80bb984c1722485584.png" />
					</div>
					<div class="top-menu-list flex wrap">
						<div class="top-menu-item pd10" v-for="(item,index) in menus" :key="index" @click="menuAct(index)" :class="{on:getSelectKey.indexOf(item.key) > -1}">
							<a-icon :type="item.icon" theme="filled" />
							<div class="ml5">{{item.name}}</div>
						</div>
					</div>
				</div>
			</a-layout-header>
			<a-layout-content class="content-height pd15">
				<router-view></router-view>
			</a-layout-content>
	    </a-layout>
	</div>
</template>

<script>
import { apiAccountInfo } from '../utils/js/api.js'
	export default{
		data(){
			return {
				siteInfo:null,
				showCourse:false,
				menus:[{name:'首页',icon:'home',key:'home',link:'/'}]
			}
		},
		created() {
			this.getAccountInfo();
		},
		methods:{
			getAccountInfo(){
				apiAccountInfo().then(res=>{
					console.log('info',res)
					this.$store.commit('setAccountInfo',res)

					if(res.role > 1){
						if(res.role != 7){
							this.menus.push({name:'客户管理',icon:'bell',key:'custom',link:'/custom'});
						}
						if(res.role != 6){
							this.menus.push({name:'交付管理',icon:'bell',key:'deliver',link:'/deliver/deliver'});
						}
						if(res.role > 7){
							this.menus.push({name:'发票管理',icon:'read',key:'invoice',link:'/invoice'});
							this.menus.push({name:'账号管理',icon:'picture',key:'account',link:'/account'});
						}
						if(res.role == 9){
							this.menus.push({name:'版本管理',icon:'picture',key:'version',link:'/version'});
						}
						//   [
						// 	{name:'首页',icon:'home',key:'home',link:'/'},
						// 	{name:'客户管理',icon:'bell',key:'custom',link:'/custom'},
						// 	{name:'交付管理',icon:'bell',key:'deliver',link:'/deliver'},
						// 	{name:'发票管理',icon:'read',key:'invoice',link:'/invoice'},
						// 	{name:'账号管理',icon:'picture',key:'account',link:'/account'},
						// 	{name:'版本管理',icon:'picture',key:'version',link:'/version'},
						// ]
					}
				})
			},
			menuAct(index){
				let data = this.menus[index];
				if(data.key != this.getSelectKey){
					this.$router.push(data.link);
				}
			},
		}
	}
</script>

<style>
	.top-menu .logo img{
		width: 125px;
		height: 40px;
	}
	.top-menu-list{
		margin:6px 40px;
	}
	.top-menu-item{
		height: 52px;
		border-radius: 8px;
		color: #9198A9;
		transition: all 0.3s;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}
	.top-menu-list .on{
		background: #4772FF;
		color:#FFFFFF;
	}
	.content-height{
		height: calc(100vh - 64px);
		overflow: auto;
	}
	.content-left-menu{
		background: #FFFFFF;
		overflow: hidden; 
		height: 100%;
	}
</style>